<template>
  <div>
    <v-form>
      <p class="mb-5">
        <v-img style="max-width: 250px" src="/img/assignments/UofA/q2_1.png" />
      </p>
      <p class="mb-3">
        In the structure of this copper-gold alloy, the shortest Cu-Au distance is 2.65Å. Calculate
        the length of the unit cell edge.
      </p>

      <p>
        <calculation-input
          v-model="inputs.input1"
          class="mb-5"
          prepend-text="Length:"
          :disabled="!allowEditing"
        />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUofA103SandboxQ2_1',
  components: {CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
